@charset "utf-8";

html,
body {
  margin: 0;
  padding: 0;
}

/* input * {
  color: rgba(0, 0, 0, 0.6) !important;
  -webkit-text-fill-color: #141414 !important;
} */

a {
  text-decoration: none;
  color: inherit;
}

.firebase-emulator-warning {
  display: none;
}
